import getters from './getters'
import actions from './actions'
import mutations from './mutations'

const byField = field => {
  return (a, b) => (a[field] > b[field] ? 1 : -1)
}
const config = JSON.parse(localStorage.getItem('configs'))

const state = {
  configs: config || null,
  instrumentNameFilter: '',
  responsibleFilter: '',
  sortedResponsiples: config && config.responsible ? config.responsible.sort(byField('last_name')) : null,
  workZoneFilter: '',
  font: localStorage.getItem('font') || 'normal',
}

export default {
  state,
  getters,
  actions,
  mutations,
}

import axios from '../axios'

async function downloadTask(rootState, params) {
  const filterResponsibleIsChecked = rootState.Task.filterResponsibleIsChecked ? 1 : 0
  const teams = rootState.Auth.user.teams
  const teamsParam = {}
  teams.forEach((team, index) => {
    teamsParam[`filter[teams][${index}]`] = team.id
  })

  const projectType = rootState.Project.projectType === 'Проекты АПР' ? 1 : 0
  const filters = {
    ...teamsParam,
    'filter[state]': 1,
    'sort[column]': params?.sortValue ?? 'created_at',
    'sort[type]': params?.sortType ?? 'desc',
    'filter[statuses][0]': 'open',
    'filter[statuses][1]': 'progress',
    'filter[statuses][2]': 'done',
    'filter[statuses][3]': 'verify',
    'filter[projects][0]': params?.projectId,
    'filter[my_tasks_today]': filterResponsibleIsChecked || undefined,
    'filter[assigned][0]': params?.assigned,
    'filter[responsible][0]': params?.responsible,
    search: params?.search,
    page: params?.currentPage,
  }

  if (projectType) {
    filters['filter[is_plan_repair]'] = projectType
  }

  const response = await axios.get('/tasks', {
    params: filters,
  })
  return [response, response.data.map(task => ({ ...task, parent: null }))]
}

export default {
  // Получение списка задач

  async downloadTaskList({ rootState, commit }, params) {
    try {
      commit('setTaskLoading', true)
      const [response, data] = await downloadTask(rootState, params)
      commit('setTaskList', data)
      commit('setTaskPageData', response)
    } catch (error) {
      console.error('error', error)
      if (!error.logout) {
        commit('Notify/setError', 'При загрузке списка рабочих заданий произошла ошибка. Попробуйте позже.', {
          root: true,
        })
      }
    } finally {
      commit('setTaskLoading', false)
    }
  },
  async downloadSubtaskList({ commit }, id) {
    try {
      const response = (await axios.get(`/task/${id}/subtasks/list`)) || []
      const data = response.map(task => ({ ...task, parent: id }))
      commit('setSubTaskList', data)
    } catch (error) {
      if (!error.logout)
        commit('Notify/setError', 'При загрузке списка подзадач произошла ошибка. Попробуйте позже.', { root: true })
    }
  },
  async updateTaskTree({ rootState, commit }, node) {
    if (!node.state_id) {
      const params = {
        projectId: rootState.Project.selectedProjectId,
        currentPage: rootState.Task.currentPage,
      }
      const [response, data] = await downloadTask(rootState, params)
      commit('updateTaskList', data)
      commit('setTaskPageData', response)
      return data
    }
    if (node.state_id || node.parent) {
      const data = await axios.get(`/get_list_task_branch?task_id=${node.id}`)
      commit('updateSubTaskList', data)
      return data
    }
  },

  async updateDeleteTask({ rootState, commit }, node) {
    const checkRootNodes = () => {
      const parentNode = rootState.Task.taskList.find(task => task.id === node.state_id)
      const childrenCount = rootState.Task.taskList.filter(task => task.parent === node.state_id)?.length
      return childrenCount === 1 && parentNode?.parent === null
    }
    if (!node.parent || checkRootNodes()) {
      const params = {
        projectId: rootState.Project.selectedProjectId,
        currentPage: rootState.Task.currentPage,
      }
      const [response, data] = await downloadTask(rootState, params)
      commit('updateTaskList', data)
      commit('setTaskPageData', response)
      return
    }
    if (node.parent) {
      rootState.Task.taskList = rootState.Task.taskList.filter(task => task.id !== node.id)
      const nextNode = rootState.Task.taskList.find(task => task.parent === node.parent)
      if (nextNode) node = nextNode
      if (!nextNode) node = rootState.Task.taskList.find(task => task.id === node.parent)
      const data = await axios.get(`/get_list_task_branch?task_id=${node.id}`)
      commit('updateSubTaskList', data)
    }
  },
  // Удаление задач из списка
  async deletedTask({ dispatch, commit }, task) {
    try {
      commit('setTaskDeleteLoading', true)
      await axios.delete(`tasks/${task.id}`)
      await dispatch('updateDeleteTask', task)
      // commit('deleteTask', task)
    } catch (e) {
      commit('Notify/setError', 'При удалении рабочего задания произошла ошибка. Попробуйте позже.', { root: true })
    } finally {
      commit('setTaskDeleteLoading', false)
    }
  },
  // Получения списка задач, которые находятся на критическом пути
  async getCriticalPathTasks(_, projectId) {
    try {
      return await axios.get('gantt/get_list_task_on_critical_path', {
        params: {
          'projects[0]': projectId,
        },
      })
    } catch (error) {
      console.error(error)
    }
  },
}

import axios from '../axios'

export default {
  async projectShow({ commit }, id) {
    // просмотр в карточке задачи
    try {
      if (id.includes(',')) return
      const response = await axios.get(`/projects/${id}`)
      commit('setProject', response)
      return response
    } catch (e) {
      console.info(e)
      if (!e.logout) {
        commit('Notify/setError', 'При загрузке произошла ошибка. Попробуйте позже.', { root: true })
      }
    }
  },
  async patternTasks({ commit }, payload) {
    try {
      return await axios.post('tasks/pattern', payload)
    } catch (e) {
      if (!e.logout) {
        commit('Notify/setError', 'При загрузке произошла ошибка', { root: true })
      }
    }
  },
  async importTasks({ commit }, payload) {
    try {
      await axios.post('tasks/import', payload)
      return true
    } catch (e) {
      if (!e.logout) {
        commit('Notify/setError', 'При загрузке произошла ошибка', { root: true })
      }
    }
  },
  async downloadPatternsList({ commit }, paramObj) {
    if (!paramObj['filter[projects][0]']) delete paramObj['filter[projects][0]']
    if (!paramObj.search) delete paramObj.search
    // Получение списка шаблонов
    try {
      const param = Object.keys(paramObj).reduce((acc, key) => (acc += `&${key}=${paramObj[key]}&`), '')
      const response = await axios.get(`tasks?type=pattern${param}`)
      const patterns = response.data

      commit('setPatterns', patterns)
      commit('setPerPage', response.total_pages)
      commit('setTotalRows', response.total)
    } catch (e) {
      if (!e.logout) {
        commit('Notify/setError', 'При загрузке списка произошла ошибка. Попробуйте позже.', { root: true })
      }
    }
  },
  async downloadProjectsList({ commit }, search) {
    // Получение списка
    try {
      // const search = state.search
      const searchParam = search ? `&search=${search}` : ''
      const response = await axios.get(`projects?limit=1000${searchParam}`)
      const projects = response.data
      commit('setProjects', projects)
    } catch (e) {
      if (!e.logout) {
        commit('Notify/setError', 'При загрузке списка произошла ошибка. Попробуйте позже.', { root: true })
      }
    }
  },
  async uploadNewProjectData({ commit }, model) {
    // Создание
    try {
      await axios.post('projects', model)
      return true
    } catch (e) {
      console.info(e)
      commit('Notify/setError', 'При сохранении произошла ошибка. Попробуйте позже.', { root: true })
      return false
    }
  },
  async uploadEditedProjectData({ commit }, payload) {
    // Редактирование
    const { id, model } = payload
    try {
      const response = await axios.put(`projects/${id}`, model)
      commit('Notify/setSuccess', 'Изменения сохранены', { root: true })
      commit('setSelectedProject', response)
      commit('setUpdatedProject', response)
      return response
    } catch (e) {
      if (e.handled || e.logout) return
      const textError = e.message.includes('403')
        ? 'У вас нет разрешения на данное действие'
        : 'При сохранении произошла ошибка. Попробуйте позже.'
      commit('Notify/setError', textError, { root: true })
    }
  },
  async uploadCloneProject({ commit }, model) {
    // Клонирование
    try {
      await axios.post(`projects/${model.id}/clone`, model)
      commit('Notify/setSuccess', 'Проект успешно склонирован', { root: true })
      return true
    } catch (e) {
      if (e.handled || e.logout) return
      commit('Notify/setError', 'При клонировании произошла ошибка. Попробуйте позже.', { root: true })
    }
  },
  async uploadDeletedProject({ commit }, id) {
    // Удаление
    try {
      await axios.delete(`projects/${id}`)
      return true
    } catch (e) {
      commit('Notify/setError', 'При удалении произошла ошибка. Попробуйте позже.', { root: true })
    }
  },
  async uploadArchiveProject({ commit }, id) {
    // Архивирование
    const data = new FormData()
    data.set('is_active', 1)
    try {
      await axios.put(`projects/${id}`, data)
      return true
    } catch (e) {
      commit('Notify/setError', 'При архивации произошла ошибка. Попробуйте позже.', { root: true })
    }
  },
  async uploadUnarchiveProject({ commit }, id) {
    // Разархивирование
    const data = new FormData()
    data.set('is_active', 0)
    try {
      await axios.put(`projects/${id}`, data)
      return true
    } catch (e) {
      commit('Notify/setError', 'При разархивации произошла ошибка. Попробуйте позже.', { root: true })
    }
  },
  async completionProject({ commit }, id) {
    // Завершение ремонта по проекту
    try {
      await axios.post(`complete_preparations_for_repairs/${id}`)
    } catch (e) {
      commit('Notify/setError', 'При завершении ремонта проекта произошла ошибка. Попробуйте позже.', { root: true })
    }
  },
  async recoveryProject({ commit }, project) {
    // Завершение ремонта по проекту
    try {
      await axios.post('projects/restore', { project: project.id })
    } catch (e) {
      commit('Notify/setError', 'При восстановлении проекта произошла ошибка. Попробуйте позже.', { root: true })
    }
  },
}

import actions from './actions'
import mutations from './mutations'
import getters from './getters'

const state = {
  taskList: [],
  isTasksLoading: false,
  isTaskDeleteLoading: false,
  tasksColorStatuses: [],
  count: 0,
  currentPage: 1,
  total: 0,
  totalPages: 0,
  perPage: 10,
  isShowGantt: false,
  filterResponsibleIsChecked: false,
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
}

// import { formatDateToListPattern } from '@/Utils/helpers'

export default {
  setIsGanttShow: (state, value) => {
    state.isGanttShow = value
  },
  hasFilteredTasks: (state, value) => (state.hasFilteredTasks = value),
  setGanttColumns: (state, value) => (state.ganttColumns = value),
  currentProjectId: (state, value) => (state.currentProjectId = value),
  setResourcesAssigned: (state, value) => (state.resourcesAssigned = value),
  setGanttList: (state, value) => {
    const { list, isOnlyTasks } = value

    if (list && list.data) {
      if (!state.ganttList || !state.ganttList.data) {
        state.ganttList = { data: [] }
      }

      const currentTasksById = state.ganttList.data.reduce((acc, task) => {
        acc[task.id] = task
        return acc
      }, {})

      list.data.forEach(newTask => {
        if (newTask.parent === 0) {
          newTask.unscheduled = true
        }

        currentTasksById[newTask.id] = { ...currentTasksById[newTask.id], ...newTask }
      })

      state.ganttList.data = Object.values(currentTasksById)
    }
    if (isOnlyTasks) {
      state.ganttList.data = list
    } else {
      state.ganttList = list
    }
  },

  setIsCopyAction: (state, value) => (state.isCopyAction = value),
  setGanttSubtasksList: (state, value) => {
    value.forEach(newTask => {
      const existingTaskIndex = state.ganttList.data.findIndex(task => task.id === newTask.id)

      if (existingTaskIndex !== -1) {
        state.ganttList.data[existingTaskIndex] = { ...state.ganttList.data[existingTaskIndex], ...newTask }
      } else {
        state.ganttList.data.push(newTask)
      }
    })
  },
  setPlannedStart: (state, value) => (state.plannedStart = value),
  setPlannedEnd: (state, value) => (state.plannedEnd = value),
  setGanttLinks: (state, value) => {
    state.ganttList.links = value.list
  },
  setGanttNewTask: (state, value) => {
    const task = {
      id: value.id,
      end_date: value.deadline,
      parent: value.state_id ? value.state_id : value.project_id,
      start_date: value.plan_date,
      text: value.name,
      responsible: value.responsible,
      expired: false,
      time_to_complete_sec: value.time_to_complete_sec,
      status: value.status,
      fact_end_date: value.fact_end_date,
      fact_start_date: value.fact_start_date,
      planned_start: value.planned_start,
      planned_end: value.planned_end,
    }
    state.ganttList.data.push(task)
  },
  setCopyTasks(state, value) {
    if (value.data) {
      state.ganttList.data.push(...value.data)
    }
    if (value.links) {
      state.ganttList.links.push(...value.links)
    }
  },
  setHasTasksForUpdate: (state, value) => {
    state.hasTasksForUpdate = value
  },
  setNewLink: (state, link) => {
    if (!link) return
    delete link.is_draft
    state.ganttList.links.push(link)
  },
  setDeleteLink: (state, id) => {
    if (!id) return
    state.ganttList.links = state.ganttList.links.filter(el => el.id !== id)
  },
  setTaskGanttPause: (state, value) => (state.taskGanttPause = value),
  updateTaskPauseGantt: (state, value) => {
    if (state.ganttList.data && state.ganttList.data.length && value) {
      const index = state.ganttList.data.findIndex(task => task.id === value.id)
      if (index !== -1) {
        const existingTask = state.ganttList.data[index]
        const updatedProperties = {
          isPause: value.isPause,
          work_sec: value.work_sec,
          pause_sec: value.pause_sec,
        }
        const updatedTaskObject = { ...existingTask, ...updatedProperties }
        state.ganttList.data.splice(index, 1, updatedTaskObject)
      }
    }
  },
  updateTaskGantt: (state, value) => {
    if (state.ganttList.data && value) {
      const index = state.ganttList.data.findIndex(task => task.id === value.id)
      if (index !== -1) {
        const taskToUpdate = state.ganttList.data[index]
        const updatedTask = {
          ...taskToUpdate,
          end_date: value.deadline || taskToUpdate.end_date,
          parent: value.state_id || value.project_id || taskToUpdate.parent,
          start_date: value.plan_date || taskToUpdate.start_date,
          text: value.name || taskToUpdate.text,
          responsible: value.responsible || taskToUpdate.responsible,
          is_exists_warehouse: value.is_exists_warehouse || taskToUpdate.is_exists_warehouse,
          expired: value.expired || taskToUpdate.expired,
          time_to_complete_sec: value.time_to_complete_sec || taskToUpdate.time_to_complete_sec,
          status: value.status || taskToUpdate.status,
          fact_end_date: value.fact_end_date ? new Date(value.fact_end_date) : taskToUpdate.fact_end_date,
          fact_start_date: value.fact_start_date ? new Date(value.fact_start_date) : taskToUpdate.fact_start_date,
          planned_start: value.planned_start ? new Date(value.planned_start) : taskToUpdate.planned_start,
          planned_end: value.planned_end ? new Date(value.planned_end) : taskToUpdate.planned_end,
        }
        state.ganttList.data.splice(index, 1, updatedTask)
      }
    }
  },
  setSelectedGantt: (state, value) => {
    state.selectedGanttTask = value
  },
  updateTaskStatusGantt: (state, { newTasks }) => {
    if (state.ganttList && state.ganttList.data) {
      for (const taskId in newTasks) {
        const newTask = newTasks[taskId]
        const taskIndex = state.ganttList.data.findIndex(t => t.id === taskId)

        if (taskIndex !== -1) {
          state.ganttList.data[taskIndex].status = newTask.status
          state.ganttList.data[taskIndex].start_date = new Date(newTask.plan_date)
          state.ganttList.data[taskIndex].end_date = new Date(newTask.deadline)
          state.ganttList.data[taskIndex].fact_end_date = newTask.fact_end_date ? new Date(newTask.fact_end_date) : null
          state.ganttList.data[taskIndex].fact_start_date = newTask.fact_start_date
            ? new Date(newTask.fact_start_date)
            : null
        }
      }
    }
  },
  setTaskWasUpdated: (state, value) => {
    state.taskWasUpdated = value
  },
  projectHasUpdated: (state, value) => (state.projectHasUpdated = value),
  setCriticalPathTasks: (state, value) => (state.criticalPathTasks = value),
}

export default {
  setImage: (state, image) => {
    if (image) {
      const imageIndex = state.images.findIndex(item => item.id === image.id)

      if (imageIndex === -1) {
        state.images = [...state.images, image]
      }
    }
  },
  setSelectedImage: (state, data) => {
    if (data) {
      state.selectedImage = data
    }
  },
  deleteImage: (state, imageId) => {
    if (imageId) {
      state.images = state.images.filter(image => image.id !== imageId)

      if (state.selectedImage.id === imageId) {
        state.selectedImage = {
          src: null,
          id: null,
          polygonData: {
            polygons: [],
            selectedPolygon: null,
            polygonCount: 0,
          },
        }
      }
    }
  },
  setVideoSources: (state, data) => {
    if (data) {
      state.videoSources = [...state.videoSources, ...data]
    }
  },
  setTotal: (state, total) => {
    if (total) {
      state.videoSourcesTotal = total
    }
  },
  setTotalPage: (state, pages) => {
    if (pages) {
      state.videoSourcesTotalPages = pages
    }
  },
  setPerPage: (state, pages) => {
    if (pages) {
      state.videoSourcesPerPage = pages
    }
  },
  createPolygon: (state, data) => {
    if (data) {
      const currentImageIndex = state.images.findIndex(image => image.id === data.imageId)

      if (currentImageIndex !== -1) {
        state.images[currentImageIndex].polygonData.polygons.push(data.polygon)
        state.images[currentImageIndex].polygonData.polygonCount = data.polygon.count + 1 // Инкрементирую счётчик полигонов
      }
    }
  },
  setSelectedPolygon: (state, data) => {
    if (data) {
      // Ищу изображение, в котором выбираем полигон
      const currentImageIndex = state.images.findIndex(image => image.id === data.imageId)

      if (currentImageIndex !== -1) {
        state.images[currentImageIndex].polygonData.selectedPolygon = data.polygonId
      }
    }
  },
  addPointToPolygon: (state, data) => {
    if (data) {
      // Ищу изображение, в котором добавляем точку к полигону
      const currentImageIndex = state.images.findIndex(image => image.id === data.imageId)

      if (currentImageIndex !== -1) {
        // Ищу полигон, к которому добавляем точку
        const currentPolygonIndex = state.images[currentImageIndex].polygonData.polygons.findIndex(
          polygon => polygon.id === data.polygonId,
        )

        if (currentPolygonIndex !== -1) {
          state.images[currentImageIndex].polygonData.polygons[currentPolygonIndex].points.push(data.point)
        }
      }
    }
  },
  updatePointForPolygon: (state, data) => {
    if (data) {
      // Ищу изображение, в котором добавляем точку к полигону
      const currentImageIndex = state.images.findIndex(image => image.id === data.imageId)

      if (currentImageIndex !== -1) {
        // Ищу полигон, к которому добавляем точку
        const currentPolygonIndex = state.images[currentImageIndex].polygonData.polygons.findIndex(
          polygon => polygon.id === data.polygonId,
        )

        if (currentPolygonIndex !== -1) {
          // Ищу точку, которую нужно изменить
          const currentPointIndex = state.images[currentImageIndex].polygonData.polygons[
            currentPolygonIndex
          ].points.findIndex(point => point.id === data.point.id)

          if (currentPointIndex !== -1) {
            state.images[currentImageIndex].polygonData.polygons[currentPolygonIndex].points[currentPointIndex] =
              data.point
          }
        }
      }
    }
  },
  deletePointFromPolygon: (state, data) => {
    if (data) {
      // Ищу изображение, в котором удаляем точку из полигона
      const currentImageIndex = state.images.findIndex(image => image.id === data.imageId)

      if (currentImageIndex !== -1) {
        // Ищу полигон, из которого удаляем точку
        const currentPolygonIndex = state.images[currentImageIndex].polygonData.polygons.findIndex(
          polygon => polygon.id === data.polygonId,
        )

        if (currentPolygonIndex !== -1) {
          state.images[currentImageIndex].polygonData.polygons[currentPolygonIndex].points = state.images[
            currentImageIndex
          ].polygonData.polygons[currentPolygonIndex].points.filter(point => point.id !== data.pointId)

          deleteEmptyPolygons(currentImageIndex)
          recalculationCounts(currentImageIndex)
        }
      }

      // Удаляем пустые полигоны
      function deleteEmptyPolygons() {
        state.images[currentImageIndex].polygonData.polygons = state.images[
          currentImageIndex
        ].polygonData.polygons.filter(polygon => polygon.points.length > 0)
      }

      // Пересчитываю счётчик и номера полигонов при удалении, т.к. меняется их количество
      function recalculationCounts(imageIndex) {
        state.images[imageIndex].polygonData.polygonCount = state.images[imageIndex].polygonData.polygons.length

        state.images[imageIndex].polygonData.polygons.forEach((polygon, index) => {
          state.images[imageIndex].polygonData.polygons[index].count = index
        })
      }
    }
  },
  deletePolygon: (state, data) => {
    if (data) {
      // Ищу изображение, в котором удаляем точку из полигона
      const currentImageIndex = state.images.findIndex(image => image.id === data.imageId)

      if (currentImageIndex !== -1) {
        state.images[currentImageIndex].polygonData.polygons = state.images[
          currentImageIndex
        ].polygonData.polygons.filter(polygon => polygon.id !== data.polygonId)
      }
    }
  },
  deleteEmptyPolygons: (state, data) => {
    if (data) {
      // Ищу изображение, в котором удаляем пустые полигоны
      const currentImageIndex = state.images.findIndex(image => image.id === data.id)

      if (currentImageIndex !== -1) {
        state.images[currentImageIndex].polygonData.polygons = state.images[
          currentImageIndex
        ].polygonData.polygons.filter(polygon => polygon.points.length > 0)
      }
    }
  },
  recalculationPolygonCounts: (state, data) => {
    if (data) {
      // Ищу изображение, в котором пересчитываем полигоны
      const currentImageIndex = state.images.findIndex(image => image.id === data.id)

      if (currentImageIndex !== -1) {
        state.images[currentImageIndex].polygonData.polygonCount =
          state.images[currentImageIndex].polygonData.polygons.length

        state.images[currentImageIndex].polygonData.polygons.forEach((polygon, index) => {
          state.images[currentImageIndex].polygonData.polygons[index].count = index
        })
      }
    }
  },
  setSendDataLoading: (state, status) => {
    state.isSendDataLoading = status
  },
  resetState: state => {
    // eslint-disable-next-line no-unused-vars
    state = {
      images: [],
      videoSources: [],
      videoSourcesPage: 1,
      videoSourcesPerPage: 0,
      videoSourcesTotal: 0,
      videoSourcesTotalPages: 0,
      selectedImage: {
        src: null,
        id: null,
        polygonData: {
          polygons: [],
          selectedPolygon: null,
          polygonCount: 0,
        },
      },
    }
  },
}

export default {
  taskList: state => state.taskList,
  isTasksLoading: state => state.isTasksLoading,
  isTaskDeleteLoading: state => state.isTaskDeleteLoading,
  currentPage: state => state.currentPage,
  count: state => state.count,
  total: state => state.total,
  totalPages: state => state.totalPages,
  perPage: state => state.perPage,
  isShowGantt: state => state.isShowGantt,
  filterResponsibleIsChecked: state => state.filterResponsibleIsChecked,
}

export default {
  setProjectList: (state, data) => {
    if (data) {
      state.projectList = data
    }
  },
  setProjectType: (state, data) => {
    if (data) {
      state.projectType = data
    }
  },
  setNewProject: (state, data) => {
    if (data) {
      state.projectList.unshift(data)
    }
  },
  changeProject: (state, data) => {
    if (data) {
      const index = state.projectList.findIndex(project => project.id === data.id)

      if (index !== -1) {
        state.projectList[index] = data
      }
    }
  },
  setSelectedProjectId: (state, data) => {
    state.selectedProjectId = data
  },
  setDeleteProject: (state, data) => {
    if (data) {
      state.projectList = state.projectList.filter(item => item.id !== data)
    }
  },
  setDeleteAprProjectAfterMove: (state, repairId) => {
    // Удаляем проекты из списка проектов АПР, которые имеют одинаковый repair id
    const repairProjects = state.projectList.filter(project => {
      const currentRepairId = project?.repair?.id

      if (currentRepairId) {
        return currentRepairId === repairId
      }
      return project
    })

    if (repairProjects.length) {
      repairProjects.forEach(project => {
        if (project.id) {
          state.projectList = state.projectList.filter(item => item.id !== project.id)
        }
      })
    }
  },
  updateProject: (state, project) => {
    state.projectList.forEach((item, index) => {
      if (item.id === project.id) {
        state.projectList[index] = project
      }
    })
  },
  setImportProjectId: (state, projectId) => {
    state.importProjectId = projectId
  },
  setProjectCreate: (state, data) => {
    state.isProjectCreate = data
  },
  setProjectLoading: (state, data) => {
    state.isProjectLoading = data
  },
  setDeleteLoading: (state, data) => {
    state.isProjectDelete = data
  },
  setRestoreLoading: (state, data) => {
    state.isProjectRestore = data
  },
  setCloneLoading: (state, data) => {
    state.isProjectClone = data
  },
  setArchiveLoading: (state, data) => {
    state.isProjectArchive = data
  },
  setUnArchiveLoading: (state, data) => {
    state.isProjectUnArchive = data
  },
  setAprProjectMoveLoading: (state, data) => {
    state.isAprProjectMove = data
  },
}

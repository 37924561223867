import getters from './getters'
import actions from './actions'
import mutations from './mutations'

const localTaskFilters = localStorage.getItem('taskFilters')
const localTaskSearch = localStorage.getItem('taskSearch')

export const initTaskFilters = {
  responsibleId: [],
  categories: [],
  manager_categories: [],
  states: [],
}

const state = {
  tasksWasDownload: false,
  myTasksResponsible: [],
  lockSaving: false,
  changeStatus: false,
  tasks: { open: [], progress: [], verify: [], done: [] },
  task: null,
  basicTeamsFromProject: [],
  myTasksToday: {},
  equivalentInstruments: [],
  subtaskGantt: {},
  tasksGantt: [],
  taskGantt: {},
  expiredTaskId: undefined,
  loading: false,
  allTasks: [],
  perPage: undefined,
  responsiblePerPage: undefined,
  assignedPerPage: undefined,
  currentPage: undefined,
  currentPagePlan: undefined,
  currentPageDone: undefined,
  currentPageProgress: undefined,
  currentPageVerify: undefined,
  currentPageSearch: undefined,
  filters: localTaskFilters ? JSON.parse(localTaskFilters) : initTaskFilters,
  search: localTaskSearch || '',
  sortGlobal: false,
  subtasks: [],
  historyTasksTMC: [],
  statusesTmc: [],
  subtasksOpenedId: null,
  projects: [],
  equipments: [],
  selectedTask: undefined,
  addFormStepLocked: false,
  taskCardScrolledOut: false,
  taskPagination: {},
  subtaskPagination: {},
  personalList: [],
  tmcList: [],
  comments: [],
  diffInstruments: {},
  updatedGantt: [],
  dateDynamicTaskGantt: {},
  hasDelete: false,
  hasAddedTaskInStatusDone: false,
  taskHasUpdated: false,
  isMergingTasksMode: false,
  mergedTaskList: [],
  tasksColorStatuses: [],
  totalRows: undefined,
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
